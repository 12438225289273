import $ from 'jquery'
window.jQuery = $ // Need this so Bootstrap 5 realizes that we're using jQuery.
import { Toast } from 'bootstrap'

export default class UCToast {
    static open(content, timeout = 5000) {
        const h = $(`
            <div class="toast">
                <div class="toast-body">
                    ${content}
                </div>
            </div>
        `)

        const toast = new Toast(h, {delay: timeout})
        $("div.toast-container").append(h)
        toast.show()
    }

    static show(alertClass, content, timeout){
        console.log(`Showing toast ${alertClass} with content ${content} for ${timeout}ms.`)
        const h = $(`
            <div class="alert alert-${alertClass} w-100" data-delay="${timeout}" style="margin-bottom: 5px;">
                ${content}
            </div>
        `)
        $("#toast").append(h)
        h.toast("show")

        $("#toast").on("hide.bs.toast", (e) => {
            $(e.target).slideUp(500)
        })
        $("#toast").on("hidden.bs.toast", (e) => {
            $(e.target).remove()
        })
    }

    static blue(c, t=3000){ UCToast.show("primary", c, t) }
    static green(c, t=3000){ UCToast.show("success", c, t) }
    static yellow(c, t=3000){ UCToast.show("warning", c, t) }
    static red(c, t=3000){ UCToast.show("danger", c, t) }
}